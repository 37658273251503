.global-message-form {
  border-radius: 2px;
  max-width: 560px;
}

.global-message-title {
  padding: 4px 4px 6px 0px;
  font-size: 16px;
  font-weight: 500;
}
