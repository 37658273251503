.login-page
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column

.ant-form.login-page-form
  width: 100%
  max-width: 350px
  background-color: #fff
  border-radius: 4px
  padding: 24px
  box-shadow: 0 15px 25px rgba(#000, 0.3)

  h2
    font-size: 24px

.phone-input-customize-wrapper
  position: relative
  width: 100%
  padding: 6.5px 11px
  font-size: 16px
  color: rgba(0, 0, 0, 0.85)
  line-height: 1.5715
  background-image: none
  border: 1px solid #d9d9d9
  border-radius: 2px
  transition: all 0.3s
  display: flex
  align-items: center

.PhoneInput,
.PhoneInputInput
  width: 100%
  border: none

  &:active,
  &:focus
    outline: none

.phone-input-customize
  .PhoneInputCountry
    display: none

    width: 100%
