.chat-view-comment {
  .ant-comment-nested {
    margin-left: 60px;
  }

  .chat-view-comment-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-comment-avatar img {
    width: 48px;
    height: 48px;
  }

  .ant-image {
    border-radius: 4px;
    overflow: hidden;
    min-width: 140px;
    min-height: 94px;
    max-width: 140px;
    max-height: 94px;
  }

  .ant-image-mask-info {
    display: flex;
    align-items: center;
    justify-content: center;
    .anticon {
      transform: translateY(-1px);
    }
  }

  .ant-comment-content-detail p {
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  .ant-comment-actions {
    margin-top: 6px;

    & > li {
      color: unset;

      & > span {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

.chat-view-uploads {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -8px;

  a {
    margin: 8px;
  }
}

.chat-view-comment-date {
  color: var(--bs-gray-600);
  cursor: pointer;
}

.chat-view-comment-user {
  font-size: 15px;
  color: #06f;
  &:hover {
    color: rgba(#06f, 0.7);
  }
}

.chat-settings-tab {
  max-width: 370px;
  padding-left: 2px;
}

.closed-padding{
  margin-left: 5px;
}
