.wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.dc-layout {
  min-height: 100vh;
  display: flex;
  max-width: 1400px;
  position: relative;

  .ant-page-header {
    padding-left: 0;
    padding-right: 0;
  }
}

.dc-layout-loading {
  position: fixed;
  left: 40px;
  bottom: 30px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  box-shadow: 0 0px 3px 0 rgba(#000, 0.1), 0 5px 10px 0 rgba(#000, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  &[data-active="true"] {
    animation-name: layoutLoadingIn;
  }
  &[data-active="false"] {
    animation-name: layoutLoadingIn;
    animation-direction: reverse;
  }
}

.dc-layout-sidebar {
  min-width: 283px;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  .logout-btn {
    &:hover {
      background-color: rgba(#dc3545, 0.1);
      color: #dc3545 !important;
      a {
        color: #dc3545 !important;
      }
    }
  }
}

.dc-layout-content {
  padding: 0 30px 10px;
  width: 100%;
}

.dc-layout-overlay {
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.3);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

::-webkit-scrollbar-track {
  background: transparent;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: rgba(#000, 0.2);
}

html:not(.is-safari):not(.is-ios) ::-webkit-scrollbar-thumb {
  opacity: 0;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.chat-row-closed {
  font-style: italic;
  opacity: 0.7;
}

.page-view {
  &.closed {
    color: var(--bs-gray-200) !important;
    font-style: italic;
  }
}

.ant-input-number {
  width: 100%;
}

.site-page-header {
  .ant-page-header-heading {
    justify-content: flex-start;
    gap: 5px;
  }
  .ant-page-header-heading-extra {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ant-tabs-tabpane-hidden {
  display: none;
}

.ant-table-row {
  &.review-like {
    background-color: rgba(#198754, 0.1);
  }
  &.review-dislike {
    background-color: rgba(#dc3545, 0.1);
  }
}

.ant-form {
  .ant-form-item.ant-form-item-with-help {
    margin-bottom: 36px;
  }
}

@media (max-width: 951px) {
  .dc-layout-content {
    padding: 0px 12px 10px;
  }
}

@keyframes layoutLoadingIn {
  0% {
    opacity: 0;
    transform: translateY(50px) scale(0.7);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
