.support-view-container {
  max-width: 960px;
  width: 100%;
  border: 1px solid rgba(#000, 0.1);
  border-radius: 4px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.support-view-messages {
  flex-grow: 1;
  overflow-y: scroll;
}

.support-view-from {
  margin-top: 20px;
  width: 100%;
  display: flex;
  form {
    width: 100%;
  }
}

.support-view-wrapper {
  display: flex;
  width: 100%;
  height: calc(100% - 72px);
  max-height: calc(100% - 145px);
}

.chat-view__dropdown-menu {
  min-width: 128px;

  .chat-view__flag {
    position: relative;
    padding-left: 28px;

    &.OPEN {
      color: #16a34a;
    }

    &.CLOSED {
      color: #b91c1c;
    }

    &.IN_WORK {
      color: #fb923c;
    }

    span.anticon {
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
