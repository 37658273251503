.dc-table {
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.no-title {
    .ant-table-title {
      display: none;
    }
  }
  &.no-footer {
    .ant-table-footer {
      display: none;
    }
  }
}

.dc-table-title {
  font-size: 16px;
  margin-bottom: 4px;
  padding: 4px 6px;
  border-bottom: 0px;
}
